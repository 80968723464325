'use client';
import { isTruthy } from '@dte/otw/azure-functions/src/common/util/strings/isTruthy';
import { SnippetOptions, init, isInitialized } from '@fullstory/browser';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { isProd } from '../../common/util/environmentConfig';

const snippetOptions: SnippetOptions = {
	orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG,
	devMode: isTruthy(process.env.NEXT_PUBLIC_FULLSTORY_DEVMODE),
	debug: isTruthy(process.env.NEXT_PUBLIC_FULLSTORY_DEBUG),
};

// TODO: remove these if FullStory exposes these types directly
interface ReadyResponse {
	sessionUrl: string;
	settings: Readonly<object>;
}
type ReadyCallback = (data: {
	sessionUrl: string;
	settings: Readonly<object>;
}) => void;

export function FullStoryInit(props: PropsWithChildren): ReactNode {
	const fullStoryInitialized = isInitialized();

	useEffect(() => {
		if (fullStoryInitialized) {
			return;
		}

		let readyCallback: ReadyCallback;
		if (!isProd) {
			console.log('FullStory init', snippetOptions);
			readyCallback = (data?: ReadyResponse) => {
				if (!isProd) {
					console.log('FullStory init complete: ', data);
				}
			};
		}

		init(snippetOptions, readyCallback);
	}, [fullStoryInitialized]);

	return props.children;
}
